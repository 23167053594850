/* ContactUs.css */

  .contact-us {
    text-align: center;
    padding: 20px;
    font-family: 'Bahiana', sans-serif;
  }
  
  .title-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .title {
    font-size: 50px;
    margin: 0 10px;
  }
  
  .line {
    flex: 1;
    border-top: 1px solid #000;
  }
  
  .contact-info {
    display: flex;
    justify-content: space-between;
  }
  
  .info,
  .message-form {
    flex: 1;
    padding: 2%;
  }
  
  .info h2,
  .message-form h2 {
    font-size: 22px;
    margin-bottom: 10px;
  }
  
  ul {
    list-style: none;
    padding: 0;
  }
  
  .info li {
    margin-bottom: 10px;
  }

  .info-group {
    padding-bottom: 30px;
  }
  
  .form-group {
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
    width: 100%;
  }
  
  #form-name {
    padding-right: 5px;
  }

  #form-email {
    padding-left: 5px;
  }

  label {
    font-size: 16px;
    display: block;
    margin-bottom: 5px;
  }
  
  input,
  textarea {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .submit-button {
    font-family: 'Bahiana', sans-serif;
    font-size: 40%;
    background-color: rgba(221,71,58,255);
    color: rgba(241, 216, 127, 1);
    font-size: 25px;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .personal-details {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  @media screen and (max-width: 992px) {
    .contact-info {
      flex-direction: column;
      align-items: center;
    }

    .info-group {
      padding-bottom: 15px;
    }
  }
