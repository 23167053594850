/* Default Styles */
* {
  margin: 0;
  padding: 0;
  color: inherit;
}

@keyframes zoom {
  0%   { background-size: 100% auto; }
  50% { background-size: 150% auto; }
  100% { background-size: 100% auto; }
}

#home {
  position: relative;
  top: -20vh;
  margin-bottom: -20vh;
  z-index: -1;
}

#home-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

#home-background {
  width: 100%;
  /* background-image: url(./assets/img1.jpg); */
  animation: zoom 60s ease-in-out infinite;
  transition: 5s;
  background-size: cover;
  background-position: center 30%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: -5
}

#home-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -5;
}

#home-container p {
  text-align: center;
  color: #F1D87F;
  font-family: 'Bahiana';
  font-size: 7em;
  color: #fff;
  padding: 35vh;
  padding-top: 45vh;
}

/* Tablet Styles */
@media screen and (max-width: 992px) {
  #home-background {
    background-position: center 20%;
    /* Mobile Remove Animation*/
    animation: none;
    transition: 5s;
  }

  #home-container p {
    font-size: 5em;
    padding: 25vh;
    padding-top: 35vh;
  }
}

/* Phone Styles */
@media screen and (max-width: 768px) {
  #home {
    top: -8vh;
    margin-bottom: -8vh;
  }

  #home-background {
    background-position: center 10%;
    /* Mobile Remove Animation*/
    animation: none;
    transition: 5s;
  }

  #home-container p {
    font-size: 3em;
    padding: 12.5vh;
    padding-top: 17.5vh;
  }
}

