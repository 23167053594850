.section-header {
  height: 100px;
  width: 100%;
  background: #5F0600;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.section-header p {
  color:#F1D87F;
  text-align: center;
  font-family: 'Bahiana';
  font-size: 55px;
  white-space: nowrap;
}

.section-header hr {
  margin-left: 5%;
  margin-right: 5%;
  width: 100%;
  border-top: 1px solid #F1D87F;
}
