.labelDivider {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 100px;
    width: 100%;
    background: #5F0600;
    border:0;
    background-size: cover;
}
.labelText{
    color:#F1D87F;
    text-align: center;
    font-family: 'Bahiana';
    font-size: 50px;
}
.label-line {
    background-color: #F1D87F;
    padding-bottom: 3px;
    width: 20%;
    border-radius: 5px;
}
.grid-food {
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin: auto;
    font-weight: bold;
    align-items: center;
    gap: 10px;
}
.grid-item {
    display: flex;
    font-family: 'Bahiana';
    color:#F1D87F;
    background-color:  rgba(95, 6, 0, 1);
    font-size: 30px;
    width: 85%;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 40%);
}
.grid-item-pic-container {
    display: flex;
    width: 85%;
    height: 300px;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 40%);
}
.grid-item-pic {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
body
{
    margin:0px;
}


@media screen and (min-width: 992px) {
    .grid-food {
        display: grid;
        justify-content: center;
        grid-column-gap: 10px;
        grid-row-gap: 10px;
        grid-template-columns: 210px 210px 210px 210px;
        font-weight: bold;
        align-items: normal;
        overflow: none;
    }
    .grid-item {
        display: flex;
        font-family: 'Bahiana';
        color:#F1D87F;
        background-color:  rgba(95, 6, 0, 1);
        font-size: 25px;
        margin: auto;
        width: 200px;
        height: 200px;
    }
    .grid-item-pic-container {
        width: 200px;
        height: 200px;
        margin: auto;
    }
}
