#root {
    background-color: rgba(C9C1BB);
}

#reviewName1 {
    font-size: 1.6em;
    font-family: HIROMISAKE;
    text-align: center;
    color: white;
}

#reviewName2 {
    font-size: 1.6em;
    font-family: HIROMISAKE;
    text-align: center;
    color: white;
}

#reviewName3 {
    font-size: 1.6em;
    font-family: HIROMISAKE;
    text-align: center;
    color: white;
}

#reviewName4 {
    font-size: 1.6em;
    font-family: HIROMISAKE;
    text-align: center;
    color: white;
}

#reviewName5 {
    font-size: 1.6em;
    font-family: HIROMISAKE;
    text-align: center;
    color: white;
}

#reviewfont1 {
    font-size: 1.6em;
    font-family: Bahianita;
    text-align: center;
    color: white;
    max-width: 900px;
    padding-left: 10%;
    padding-right: 10%;
    padding-top:30px;
}

#reviewfont2 {
    font-size: 1.6em;
    font-family: Bahianita;
    text-align: center;
    color: white;
    max-width: 900px;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 30px;
}

#reviewfont3 {
    font-size: 1.6em;
    font-family: Bahianita;
    text-align: center;
    color: white;
    max-width: 900px;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 30px;
}

#reviewfont4 {
    font-size: 1.6em;
    font-family: Bahianita;
    text-align: center;
    color: white;
    max-width: 900px;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 30px;
}

#reviewfont5 {
    font-size: 1.6em;
    font-family: Bahianita;
    text-align: center;
    color: white;
    max-width: 900px;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 30px;
}

#Reviews {
    font-size: 50px;
    color: #F1D87F;
    text-align: center;
    font-family: 'Bahiana';
    padding: 20px;
}

#backgroundMarbel {
    background-image: url("./ReviewTextImages/absolute-black-marble-surface.png");
}

#ReviewTitle {
    font-size: 50px;
    color: white;
    text-align: center;
    font-family: Bahianita;
    padding-top: 10px;
    padding-bottom: 30px;
}

#ReviewTexts {
    font-size: 18px;
    display: flex;
    align-content: center;
    text-align:center;
    justify-content: center;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    height: 1300px;
    width: 100%;
    padding-bottom: 75px;
}

.spacingReview {
    background-image: url("./ReviewTextImages/borderR1.png");
    background-size: cover;
    background-repeat: "no-repeat";
    background-position: 'center';
    text-align: center;
    width:100%;
    margin-top: 20px;
    max-width: 1100px;
    height: 250px;
}

#spacingReview2 {
    background-image: url("./ReviewTextImages/borderR.png");
    background-size: cover;
    background-repeat: "no-repeat";
    background-position: 'center';
    text-align: center;
    margin-top: 20px;
    max-width: 1100px;
    height: 200px;
}

#bmreview {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    overflow: hidden;
    width: 100%;
}

#imageResturant {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    object-fit: cover;
    padding: 5%;
    gap: 6%;
    width: 80%;
    height: 80%;
    margin: auto;
    justify-content: center;
}

#borderReview {
    flex-direction: row;
    align-content: space-around;
    background: #5F0600;
    align-items: center;
    height: 100px;
    width: 100%;
}

@font-face {
    font-family: 'HIROMISAKE';
    src: local('HIROMISAKE'), url('./fonts/HIROMISAKE.woff') format('woff')
}

@font-face {
    font-family: 'Bahianita';
    src: local('Bahianita'), url('./fonts/Bahianita-Regular.woff') format('woff')
}

@media screen and (max-width: 992px) {

    #ReviewTitle {
        font-size:40px;
    }

    #ReviewTexts {
        height:2000px;
    }

    .spacingReview, #spacingReview2 {
        background-image: url("./ReviewTextImages/borderR-mobile.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        width:90%;
        height:400px;
        max-width:500px;
        min-width: 300px;
        box-sizing: border-box; 
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    #reviewfont1, #reviewfont2, #reviewfont3, #reviewfont4, #reviewfont5 {
        width:80%;
    }

    #reviewName1, #reviewName2, #reviewName3, #reviewName4, #reviewName5 {
        font-size: 1.2em;
        padding-top: 1%;
        padding-left: 5%;
        padding-right: 5%;
        margin-top: 3%;
    }

    .bmr {
        width:100%;
        height:100%;
        max-width:150px;
        max-height:50px;
    }
}

@media screen and (max-width: 768px) {

    #ReviewTexts {
        height:1800px;
    }

    #ReviewTexts {
        font-size:14px;
    }

    .bmr {
        width:90%;
        height:90%;
        max-width:120px;
        max-height:40px;
    }
}

@media screen and (max-width: 480px) {
    #ReviewTexts {
        height:1400px;
    }
}