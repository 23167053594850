
.labelDivider {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100px;
    width: 100%;
    background: #C9C1BB;
    border: 0;
    background-size: cover;
}

.labelText {
    color: black;
    text-align: center;
    font-family: 'Bahiana';
    font-size: 50px;
}

.label-line {
    background-color: black;
    padding-bottom: 3px;
    width: 20%;
    border-radius: 5px;
}

.menuTextDesciption {
    color: red;
    text-align: center;
    background: #C9C1BB;
    font-size: 18px;
    font-weight: 600;
}

.smallerMenuTextDescription {
    color: red;
    text-align: center;
    background: #C9C1BB;
    font-size: 12px;
    font-weight: 600;
}

.bmenu {
    display: block;
    margin-left: auto;
    margin-right: auto;
    background-image: url("./Mimages/Meny Border_Colored.png");
    background: #C9C1BB;
    max-width: 80%;
}

.lineDividerMenu {
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    background: #C9C1BB;
    height: 30%;
    width: 50%;
}

.menuImages {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-around;
}

.itemList {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.columnMenu {
    display: grid;
    grid-template-columns: 1fr 2fr;
    margin-bottom: 20px;
}

.foodContainer {
  display: flex;
  position: relative;
  color: #F1D87F;
  max-width: 50%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  z-index: 0;
}

.foodContainer p {
  display: flex;
  position: absolute;

  padding: 0.2em 0.5em;
  min-width: fit-content;
  white-space: nowrap;
  bottom: 15px;
  left: 50%;
  right: 50%;
  transform: translate(-50%, 0%);
  
  background-color: #5F0600;
  align-items: center;
  justify-content: center;

  border-radius: 10px;
  font-family: 'Bahianita', sans-serif;
  font-size: 1.5vw;
}

.foodImage {
  width: 100%;
  height: 100%;
}

.spicyCenter {
  display: flex;
  flex-direction: column;
  color: black;
  font-weight: bold;
  text-align: center;
  font-size: 20px;
  flex: 40;
}

@media screen and (max-width: 992px) {
    .bmenu {
      max-width: 100%;
      margin-left: 10px;
      margin-right: 10px;
    }

    .columnMenu {
      display: grid;
      grid-template-columns: none;
      grid-template-rows: min-content 1fr;
      gap: 30px;
    }

    .menuImages {
      min-width: 200px;
      max-width: 500px;
      margin: auto;
      gap: 10px;
    }

    .foodContainer p {
      font-size: clamp(18px, 5vw, 30px);
    }
    
}