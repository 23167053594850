/* Default Styles */
* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style-type: none;
  color: inherit;
  scroll-behavior: smooth;
}

:target {
  scroll-margin-top: 17.5vh;
}

header {
  position: sticky;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 5;
}

#top-bar {
  background-color: rgba(95, 6, 0, 1);
  color: white;

  font-family: 'M PLUS 1p', sans-serif;
  font-weight: normal;
  font-size: 0.85em;

  display: flex;
  justify-content: space-between;
  padding: 0.25% 1%;
}

#top-bar p {
  color: rgba(241, 216, 127, 1);
}

#nav-bar {
  width: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.9) 60%, transparent 40%);
  display: flex;
  justify-content: center;
}

#nav-bar a, #mobile-nav a {
  color: white;
  /* font-family: 'M PLUS 1p', sans-serif; */
  font-family: 'Bahiana', sans-serif;
  font-weight: bold;
}

#nav-bar a {
  font-size: 2em;
}

#mobile-nav a {
  font-size: 2.5em;
}

.nav-links, .mobile-nav-links {
  width: 35%;  
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.nav-links {
  margin-bottom: 2.75%;
}

#nav-bar a:hover,
#mobile-nav a:hover,
#top-bar a:hover {
  transition-duration: .2s;
  color: rgba(252, 214, 1, 1);
}

#mobile-logo-container {
  display: none;
}

.mobile-nav-links {
  display: none;
}

#desktop-logo-container {
  display: block;
  width: 22.5%;
  padding-top: 1.5%;
}

#logo {
  width: 100%;
}

.content {
  font-size: 5em;
}

/* Tablet Styles */
@media screen and (max-width: 992px) {
  :target {
    scroll-margin-top: 15vh;
  }

  #top-bar {
    font-size: 0.7em;
  }

  #desktop-logo-container {
    width: 30%;
    padding-top: 1.5%;
  }

  .nav-links {
    width: 35%;
    margin-bottom: 3.5%;
  }

  #nav-bar a {
    font-size: 1.5em;
  }
}

/* Phone Styles */
@media screen and (max-width: 768px) {
  :target {
    scroll-margin-top: 10vh;
  }

  #logo {
    width: 50%;
    margin: 2.5%;
  }

  #top-bar {
    font-size: 0.7em;
    padding: 0.25% 2.5%;
  }

  #mobile-logo-container {
    background: linear-gradient(rgba(0, 0, 0, 0.9) 60%, transparent 40%);
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  #menu-icon {
    z-index: 2;
    cursor: pointer;
    width: 10%;
    margin: 0 2.5%;
  }

  .bar1, .bar2, .bar3 {
    width: 100%;
    height: 8%;
    background-color: white;
    margin: 15% 0;
    transition: 0.4s;
  }

  .change .bar1 {
    transform: translate(0, 210%) rotate(-45deg);
  }

  .change .bar2 {opacity: 0;}

  .change .bar3 {
    transform: translate(0, -210%) rotate(45deg);
  }

  #nav-bar {
    display: none;
    background: none;
  }

  .mobile-nav-links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    visibility: hidden;
    max-height: 0;
  }

  .nav-change .mobile-nav-links {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.95);
    overflow-x: hidden;
    transition: 0.4s;
    visibility: visible;
    max-height: 100%;
  }

  .nav-change .mobile-nav-links a {
    display: flex;
    margin: 25px 0;
  }
}