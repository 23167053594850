body {
    margin: 0;
    padding: 0;
}

hr {
    margin-left:5%;
    margin-right:5%;
    width:50%;
    border-top: 1px solid #F1D87F;
}

.top-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index:-1;
}

.top-background {
    background-image: url('./ReviewTextImages/hong-kong-kitchen-about.webp');
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    z-index:-5
}

.top-overlay {
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index:-5;
}

.top-container p {
    text-align: center;
    color: #F1D87F;
    font-family: 'Bahiana';
    font-size: 36px;
    color: #fff;
    padding:10%;
}

.bot-container {
    position:relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    z-index:-2;
}

.bot-text {
    width:100%;
    background-color: #5F0600;
    text-align: center;
    color: #F1D87F;
    font-family: 'Bahiana';
    font-size: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: -15px;
}

.bot-text p {
    width:100%;
    margin-top: 10px;
    margin-bottom: 10px;
}

.left-text, .right-text {
    width:50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.bot-img {
    display: flex;
    justify-content: center;
    padding-top: 5px;
    padding-bottom: 5px;
}

.bot-img img {
    max-width: 50%;
    margin-right: 5px;
}

.circle-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index:-1;
}

.outside-circle {
    width: 30%; 
    height: 30%; 
    aspect-ratio: 1; 
    min-width: 350px;
    min-height: 350px;
    max-width: 450px;
    max-height: 450px;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -40%;
}

.center-circle {
    width: 95%; 
    height: 95%;
    aspect-ratio: 1;
    min-width: 330px;
    min-height: 330px;
    max-width: 430px;
    max-height: 430px;
    background-color: #5F0600; 
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.inner-circle {
    width: 90%; 
    height: 90%;
    min-width: 300px;
    min-height: 300px;
    max-width: 400px;
    max-height: 400px;
    aspect-ratio: 1; 
    border-style: solid;
    border-color:#F1D87F;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.circle-text {
    color: #F1D87F; 
    font-family: 'Bahiana';
    font-size: 50px;
    text-align: center; 
}

.circle-text h1 {
    font-size: 180px;
}

.circle-text h2 {
    margin-top:-15%;
    margin-bottom: -15%;
    font-size: 40px;
}

@media screen and (max-width: 992px) {
    .bot-img {
        flex-direction: column;
    }

    .bot-img img {
        max-width: 100%;
        margin-right: 0px; 
        margin-bottom: 5px; 
    }

    .bot-text {
        font-size: 20px;
    }

    .outside-circle {
        margin-top: -150%;
        width: 50%; 
        height: 50%;
        min-width: 300px;
        min-height: 300px;
    }

    .center-circle {
        min-width: 270px;
        min-height: 270px;
    }

    .inner-circle {
        min-width: 240px;
        min-height: 240px;
    }

    .circle-text h1 {
        font-size: 150px;
    }
    
    .circle-text h2 {
        font-size: 30px;
    }

    .top-container p {
        font-size: 24px;
    }
}
