.menuItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

.menuFood {
  display: flex;
  flex-direction: column;
  color: black;
  font-weight: bold;
  text-align: left;
  font-size: 20px;

}

.menuSubtitle {
  font-size: 18px;
  font-weight: 600;
}

.menuPrice {
  display: flex;
  flex-direction: column;
  color: black;
  font-weight: bold;
  font-size: 20px;
  margin-left: auto;
}

@media screen and (max-width: 992px) {
}