.footer-container {
  width: 100%;
  margin-top: auto;
}
.footer {
    background-color: maroon;
    color: white;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
    width: 100%;
  }
  
  .footer-part {
    text-align: center;
    flex: 1;
    font-family: 'Bahiana';
    font-size: 15px;
    padding: 10px;
  }
  
  @media screen and (min-width: 992px) {
    .footer-container {
     width: 100%;
    }
    .footer {
        background-color: maroon;
    	color: white;
    	display: flex;
    	justify-content: space-evenly;
    	align-items: center;
      padding-top: 5px;
      padding-bottom: 5px;
    }
    .footer-part {
     	text-align:center;
      padding: 10px;
	flex: 1;
	font-family: 'Bahiana';
	font-size: 20px;
    }
}
  
